/* src/PhotoCarousel.css */

.carousel-container {
  max-width: 1400px;
  margin: 5rem auto;
  padding: 0px;
  border-radius: 20px;
}

.photo-card {
  padding: 10px;
  font-size: 3rem;
}

.photo-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.suv {
margin-bottom: 3rem;
text-align: center;
}

.car-card {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5.2px 10.4px 10.4px hsl(0deg 0% 0% / 0.34);
}

.car-card img {
  width: 100%;
  height: auto;
}

.car-info {
  text-align: center;
  margin-top: 1.5rem;
}

.car-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.car-price {
font-size: 1.4rem;
margin-bottom: 5rem;
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
  .car-card {
    width: 100% !important; /* Ensure only one card is visible */
  }

  .car-price {
    flex-direction: column;
  }
  .slick-slider{
    width: 100% !important;
    margin: auto;
  }
  .slick-list{
    min-height: 530px;
  }
  .slick-slide > div{
    display: flex;
    width: 90%;
    margin: auto;
  }
}

h1 {
  font-size: 2.5rem;
  color: "#00274C";
  margin-bottom: 5px;
}