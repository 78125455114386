
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
  overflow: hidden;
  overflow-y: scroll;
}

.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
  
}
#root > div{
  overflow: hidden;
}
@media (max-width: 768px) {
  .bpYRa-D h1{
    font-size: 5vw !important;
  }
  .bpYRa-D{
    padding: 0 !important;
  }
  .bpYRa-D p{
    font-size: 4vw !important;
  }
}