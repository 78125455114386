.partner-container{
  max-width: 1400px;
  margin: auto;
  margin-bottom: 8rem;
}

.partner-banner-container {
  position: relative;
  display: flex;
  padding-top: 2rem;
}
.partner-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
  overflow: hidden;
  
}
.partner-image-section {
    display: flex;
    justify-content: space-around;
    width: 70%;
    max-width: 1000px;
}

.partner-image {
  width: 80%;
  margin: auto;
  border-radius: 20px;
  box-shadow: 5.2px 10.4px 10.4px hsl(0deg 0% 0% / 0.34);
}

@media (max-width: 768px) {
  .partner-banner-container {
    flex-direction: column !important;
    padding: 0;
  }
  .partner-image-section {
    align-items: center !important;
    margin: 1rem auto 0; 
    width: 90%;
  }
  .partner-image-section img{
    width: 100%;
  }
  .partner-image {
    width: 100% !important;
  }
}