.rightBackground {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}

@media (max-width: 600px) {
  .rightBackground {
    right: -500px;
  }
}

.one {
  line-height: 2.5rem;
}

.two {
  margin: 1rem auto 2rem;
  width: 17rem;
  /* background-color: red; */
}

@media (max-width: 768px){
  
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
  overflow: hidden;
  overflow-y: scroll;
}
  .bnkimL{
    align-items: center;
  }
  .lbVpSW h1{
    font-size: 5vw !important;
  }
  .lbVpSW p{
    font-size: 4vw !important;
  }
}