.bene {
  text-align: center;
} 

h1 {
  margin: 5rem 0;
  font-size: 2em;
}

.bene-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem auto 4.5rem;
  
}

.bene-image-wrapper {
  position: relative;
  width: 18rem;
  height: 19rem;
  margin: 0 10px;
}

.bene-image-wrapper img {
  width: 100%;
  height: 100%;
  display: block;
  filter: brightness(50%);  
  border-radius: 50px 0 50px 0;
  box-shadow: 5.2px 10.4px 10.4px hsl(0deg 0% 0% / 0.34);
}

.bene-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  white-space: pre-wrap;
}

.info-boxes-img-container {
  
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .bene-image-container {
    gap: 10px;
    flex-direction: column;
  }

  .bene-overlay .text {
    font-size: 16px;
  }
  .bgJBVK h1{
    font-size: 8vw !important ;
  }
  .bgJBVK p{
    font-size: 4vw !important;
  }
  .bgJBVK p br{
    display: none;
  }

  .bene-image-wrapper{
    width: 90% !important;
  }
}

@media (max-width: 480px) {
  .bene h1 {
    font-size: 24px;
    margin-top: 2rem;
  }
  .bene-image-container {
    gap: 10px;
    flex-direction: column;
  }

  .bene-overlay .text {
    font-size: 24px;
  }
}
