li,ul {
  list-style: none;
}

.csj-image {
  width: 100%;
}

.form-section-container {
  margin-top: 2rem;
  position: relative;
  display: flex;
  align-items: center;

  justify-content: center;
  gap: 40px;
  padding: 0 10px;

}
.form-section-image-container {
  flex: 1;  
  max-width: 572px;  
  min-width: 572px;
  border-radius: 20px;
  overflow: hidden;
}
.customer-form-container {  
  min-height: 576px;
  min-width: 470px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
  background-color: #f8f7f2;
}
.customer-form-container h3 {
  font-size: 1.4rem;  
  text-align: center;
  padding: 1.2rem 0 2.2rem;
  color: #024886;
}

.form-wrap {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 14px;
}
.form-item {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}
.from-error-wrap {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.from-error-wrap .error {  
  font-size: 14px;
  margin-top: 8px;
  color: red;
  opacity: 0.7;  
  margin-right: 14px;
}
.form-success-wrap {
  width: 100%;
  margin-bottom: 14px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.form-success-wrap .success-message {

}

.form-item label {
  display: inline-block;
  width: 80px;
  font-size: 1rem;
  font-weight: bold;
  color: #024886;
}
.form-item input {
  width: 320px;
  background-color: transparent;
  font-size: 1rem;
  padding: 14px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.form-item input::placeholder {  
  color: rgba(0, 0, 0, 0.2) !important;  
}


.check-wrap {
  padding: 1rem 0;
}
.check-wrap ul {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}
.check-wrap ul li {
  width: 100%;
  font-size: 0.8rem;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: rgba(0, 0, 0, 0.7);
}
.check-wrap ul li .reading {
  border-bottom: 1px solid;
  cursor: pointer;
}

.submit-btn {
  width: 100%;
  padding: 14px 0;
  color: #FFCB05;
  background-color: #00274C;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width:768px) {

  .form-section-image-container {
    flex: 1;      
    min-width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }
  .customer-form-container {
    width: 100%;
    min-width: 100%;
  }
  .form-section-container {
    flex-direction: column;
  }
  .form-item input {
    width: 80%;
  }
  .form-item-deep {
    width: 100%;
  }
}