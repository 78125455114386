.fast-section-wrapper {
  white-space: pre-wrap;
  margin-top: 5rem;
  max-width: 1400px;
  margin: auto;
}
.fast-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.fast-section-top h1 {
  max-width: 700px !important;
}
.fast-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fast-section-info {
  width: 17rem;
  min-height: auto;
  background-color: white;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #505050;
  margin: 1rem 1rem;
  box-shadow: 5.2px 10.4px 10.4px hsl(0deg 0% 0% / 0.34);
  border-radius: 20px;
}
.fast-section-info h2 {
  margin: 2rem 0rem;
}
.fast-section-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  
}

.fast-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.fast-boxes-img-container img {
  width: 13rem;
  height: auto;
}

@media (max-width: 768px) {
  .fast-section-bottom{
    flex-wrap: wrap;
  }
  .fast-section-info{
    width: 60%;
  }
  .fast-section-top h1{
    font-size: 5.5vw !important;
  }
}
