/* .rightBackground {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
} */

/* @media (max-width: 600px) {
  .rightBackground {
    display: none;
  }
} */
.bnkimL{
  position: relative;
}
.one {
  lineHeight: 2.5rem;
}

.two {
  margin: 1rem auto 1rem;
  backgroundColor: #00ff00;
  width: 17rem;
}
.rightBackground img{
  width: 100%;
}
/* Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1400px;
  margin: 3rem auto;
  
}
@media (min-width: 768px) {
  flex-direction: row;
}
  

TextSection {
  flex: 1;
  padding: 20px;
  text-align: center;
}
  
  

  h1 {
    font-size: 2.5rem;
    color: "#00274C";
    margin-bottom: 10px;
    line-height: 4rem;
  }

  p {
    font-size: 1.5rem;
    color: "#00274C",
  }


ImageSection {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
 

ImageSection img {
    width: 100%;
    height: auto;
    max-width: 1080px;
    max-height: 1080px;
    border-radius: 8px;
    box-shadow: 10px 5px 5px gray;
  }
 */
