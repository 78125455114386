.rent-section-wrapper {
  white-space: pre-wrap;
  margin-top: 5rem !important;
  max-width: 1400px;
  margin: auto;
}
.rent-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.rent-section-top h1 {
  max-width: 700px !important;
}
.rent-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rent-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 3rem auto;
  box-shadow: 5.2px 10.4px 10.4px hsl(0deg 0% 0% / 0.34);
  border-radius: 20px;
}
.rent-section-info h2 {
  margin: 1rem 0rem;
}
.rent-section-bottom {
  margin: 2rem 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(3, 1fr);  
}
.rent-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.info-boxes-img-container img{
    width: 90%;
    height: 90%;
}
@media (max-width: 768px) {
  .rent-section-bottom {
    grid-template-columns: repeat(1, 1fr);  
    width: 90%;
  }
  .rent-section-info{
    width: 70%;
    margin: 1rem auto;
  }
  .rent-section-top h5{
    font-size: 5vw !important;
  }
}


