.footer {
  width: 100%;
  background-color: #00274C;
  color: #FFCB05;
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
  margin-top: 5rem;
}

.footer-content {
  width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10%;
}

.text-section {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  text-align:left;
  
}

.text-section p {
  margin: 5px 0;
}

.logo-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-left: 10rem;
  margin-right: 10rem; */
}

.logo-section img {
  width: 150px;
  height: auto;
  border-radius: 20px;
}
.sns-section{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 20px;
}

.sns-section img{
  width: 50px;
  border-radius: 10px;
}
@media (max-width: 900px) {
  .footer-content {
    width: 100%;
    padding: 0 20px;
  }
  .footer-content{
    flex-direction: column;
  }

  .footer{
    padding: 40px 0;
  }
  .logo-section{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .sns-section{
    flex-direction: unset;
    margin: 20px;
  }
  .text-section {
    margin-bottom: 20px;
    text-align: center;
  }
}