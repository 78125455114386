* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

h1 {
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 700;
  font-style: normal;
}


.nanum-gothic-regular {
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.nanum-gothic-bold {
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.nanum-gothic-extrabold {
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 800;
  font-style: normal;
}

  
  
  
  
  
  
  
  
  
  
  
